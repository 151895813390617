<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Cost Type</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr v-if="costDetails.costType === TOTAL_COSTS">
          <td>Total Cost of Goods Sold (COGS)</td>
          <td>
            <div v-if="costDetails.totalCOGS > 0" class="text-danger">
              -${{ costDetails.totalCOGS }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.totalCOGS || 0 }}
            </div>
          </td>
        </tr> -->
        <tr v-if="costDetails.costType === TOTAL_COSTS">
          <td>Amazon Referral Fee</td>
          <td>
            <div v-if="costDetails.referralFee > 0" class="text-danger">
              -${{ costDetails.referralFee }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.referralFee || 0 }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === TOTAL_COSTS">
          <td>FBA Fee</td>
          <td>
            <div v-if="costDetails.fbaFee > 0" class="text-danger">
              -${{ costDetails.fbaFee }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.fbaFee || 0 }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === TOTAL_COSTS">
          <td>MFN Shipping</td>
          <td>
            <div v-if="costDetails.mfnShipping > 0" class="text-danger">
              -${{ costDetails.mfnShipping }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.mfnShipping || 0 }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === TOTAL_COSTS">
          <td>Misc Fees</td>
          <td>
            <div v-if="costDetails.miscFee > 0" class="text-danger">
              -${{ costDetails.miscFee }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.miscFee || 0 }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === ORDER_TOTAL">
          <td>Items Total</td>
          <td>
            <div>
              ${{ costDetails.totalSales }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === ORDER_TOTAL">
          <td>Shipping Total</td>
          <td>
            <div>
              ${{ costDetails.amzShipping }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === ORDER_TOTAL">
          <td>Grand Total</td>
          <td>
            <div class="text-success">
              <strong style="font-size: 18px; font-weight: 900;">
                {{ costDetails.grandTotal }}
              </strong>
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === OTHER_COSTS">
          <td>Warehouse Service Plan Fee</td>
          <td>
            <div v-if="costDetails.planFee > 0" class="text-danger">
              -${{ costDetails.planFee }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.planFee || 0 }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === OTHER_COSTS">
          <td>Prep Material</td>
          <td>
            <div v-if="costDetails.prepFee > 0" class="text-danger">
              -${{ costDetails.prepFee }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.prepFee || 0 }}
            </div>
          </td>
        </tr>
        <tr v-if="costDetails.costType === OTHER_COSTS">
          <td>Shipping to Amazon Cost</td>
          <td>
            <div v-if="costDetails.shippingFee > 0" class="text-danger">
              -${{ costDetails.shippingFee }}
            </div>
            <div v-else class="text-secondary">
              {{ costDetails.shippingFee || 0 }}
            </div>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
const ORDER_TOTAL = 'orderTotal'
const TOTAL_COSTS = 'totalCosts'
const OTHER_COSTS = 'otherCosts'

export default {
  props: {
    costDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ORDER_TOTAL,
      TOTAL_COSTS,
      OTHER_COSTS,
    }
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  min-width: 250px;
  border-collapse: collapse;
}

thead {
  background-color: rgba(0, 0, 0, 0.1);
}

th {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

td {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
td:nth-child(2) {
  text-align: end;
}
</style>
